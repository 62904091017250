import { permissionsConstant } from "@/helpers/permissionsConstant";
export default [
  {
    path: "/admin/lead/types",
    name: "admin-lead-types",
    component: () => import("@/views/admin/lead-management/lead-type/LeadTypeView"),
    meta: {
      gate: permissionsConstant.LEAD_TYPE_ACCESS,
      requiresAuth: true,
      pageTitle: "Type",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Types",
          active: true,
          to: "",
        },
      ],
    },
  },
];

export const permissionsConstant = {
  // Dashboard
  DASHBOARD_ACCESS: "dashboard_access",

  // Division
  DIVISION_ACCESS: "division_access",
  DIVISION_CREATE: "division_create",
  DIVISION_EDIT: "division_edit",
  DIVISION_DELETE: "division_delete",

  // Department
  DEPARTMENT_ACCESS: "department_access",
  DEPARTMENT_CREATE: "department_create",
  DEPARTMENT_SHOW: "department_show",
  DEPARTMENT_EDIT: "department_edit",
  DEPARTMENT_DELETE: "department_delete",
  DEPARTMENT_EMPLOYEE_ADD: "department_employee_add",
  DEPARTMENT_EMPLOYEE_DELETE: "department_employee_delete",

  // Designations
  DESIGNATION_ACCESS: "designation_access",
  DESIGNATION_CREATE: "designation_create",
  DESIGNATION_SHOW: "designation_show",
  DESIGNATION_EDIT: "designation_edit",
  DESIGNATION_DELETE: "designation_delete",

  // Roles
  ROLES_ACCESS: "roles_access",
  ROLES_CREATE: "roles_create",
  ROLES_SHOW: "roles_show",
  ROLES_EDIT: "roles_edit",
  ROLES_DELETE: "roles_delete",

  // Users
  USERS_ACCESS: "users_access",
  USERS_CREATE: "users_create",
  USERS_SHOW: "users_show",
  USERS_EDIT: "users_edit",
  USERS_DELETE: "users_delete",
  USERS_PROFILE_SHOW: "users_profile_show",
  USERS_PROFILE_EDIT: "users_profile_edit",

  LEAD_MANAGEMENT_ACCESS: "lead_management_access",  // Lead Management
  LEAD_TYPE_ACCESS: "lead_type_access",  // Lead Type
  CAMPAIGN_ACCESS: "campaign_access",  // Campaign

  // Employee Types
  EMPLOYEE_TYPES_ACCESS: "employee_types_access",
  EMPLOYEE_TYPES_CREATE: "employee_types_create",
  EMPLOYEE_TYPES_SHOW: "employee_types_show",
  EMPLOYEE_TYPES_EDIT: "employee_types_edit",
  EMPLOYEE_TYPES_DELETE: "employee_types_delete",

  // Lead Generator
  LEAD_GENERATOR_ACCESS: "lead_generator_access",
  LEAD_GENERATOR_SHOW: "lead_generator_show",


  // List of Lead
  LIST_OF_LEAD_ACCESS: "list_of_lead_access",
  LEADS_SHOW: "leads_show",

  // Lead Details
  LEAD_DETAILS: "lead_details",




  
};

// import { permissionsConstant } from "@/helpers/permissionsConstant";

export default [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/auth/LoginView.vue"),
    meta: {
      pageTitle: "Login",
      requiresAuth: false,
      requiresVisitor: true,
      layout: "full",
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/auth/ForgotPasswordView.vue"),
    meta: {
      pageTitle: "Forgot Password",
      requiresAuth: false,
      requiresVisitor: true,
      layout: "full",
    },
  },
  {
    path: "/reset-password",
    name: "auth-reset-password",
    component: () => import("@/views/auth/ResetPasswordView.vue"),
    meta: {
      pageTitle: "Reset Password",
      requiresAuth: false,
      requiresVisitor: true,
      layout: "full",
    },
  },
];
